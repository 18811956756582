export const KeyFeature4 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79" fill="none">
            <g clip-path="url(#clip0_22_98)">
                <path d="M76.2534 63.4497L55.8758 76.5083C54.6901 77.259 53.3169 77.6605 51.9136 77.6667H23.3217C16.3712 77.6667 14.4887 74.0993 14.4887 74.0993V73.7175H10.5396V57.9209H14.4887V58.013C16.7923 54.4983 21.3471 53.2871 24.2167 52.8791H24.2298C25.0707 52.748 25.9195 52.6732 26.7704 52.6553H27.4418C29.7098 52.6616 31.9037 53.4627 33.6419 54.9194C35.3688 56.3902 37.5607 57.2017 39.829 57.2099H45.5158C47.4547 57.2206 49.2388 58.2709 50.1889 59.9612L50.281 60.1192C50.9795 61.3505 51.0287 62.8464 50.4126 64.1209C50.2423 64.4651 50.0252 64.7841 49.7676 65.0687H52.2556C52.7214 65.0737 53.1845 64.9981 53.6246 64.8449L71.5538 58.5526L73.7389 57.7759C74.0529 57.6702 74.3819 57.6169 74.7131 57.6179C75.6584 57.6283 76.5407 58.0938 77.0826 58.8686C77.585 59.5845 77.78 60.4717 77.6242 61.3321C77.4686 62.1929 76.9749 62.9553 76.2534 63.4497Z" fill="#BFA885" />
                <path d="M70.988 63.4495L50.6103 76.5081C49.4247 77.2587 48.0514 77.6603 46.6481 77.6665H23.3217C16.3712 77.6665 14.4887 74.0991 14.4887 74.0991V73.7173H10.5396V57.9207H14.4887V58.0128C16.7923 54.4981 21.3471 53.2869 24.2167 52.8789H24.2298C25.7558 53.2163 27.1781 53.9162 28.3764 54.9194C30.1033 56.3901 32.2952 57.2017 34.5635 57.2099H40.2503C42.1892 57.2205 43.9733 58.2709 44.9234 59.9612L45.0155 60.1191C45.9233 61.7083 45.7168 63.6996 44.5021 65.0687H46.9901C47.4559 65.0736 47.919 64.998 48.3592 64.8448L50.4127 64.1209C50.2424 64.465 50.0254 64.7841 49.7677 65.0687H52.2557C52.7216 65.0736 53.1847 64.998 53.6248 64.8448L71.5539 58.5526C71.6519 58.649 71.74 58.7548 71.8173 58.8685C72.3197 59.5844 72.5147 60.4716 72.3589 61.3321C72.2031 62.1927 71.7093 62.9551 70.988 63.4495Z" fill="#FFE0B2" />
                <path d="M77.6748 23.6945C77.6752 29.6298 75.3178 35.3221 71.1209 39.519C66.924 43.7159 61.2317 46.0735 55.2964 46.0729C52.9773 46.0772 50.6718 45.7175 48.4643 45.0066L42.1326 50.0221V41.7814C33.5925 35.5655 30.5626 24.2241 34.8649 14.5773C39.1672 4.93061 49.6295 -0.393649 59.9604 1.80646C70.2913 4.00657 77.6763 13.1319 77.6748 23.6945Z" fill="#2296F3" />
                <path d="M2.64104 55.2881H13.1722C13.8992 55.2881 14.4885 55.8774 14.4885 56.6044V75.0338C14.4885 75.7609 13.8992 76.3502 13.1722 76.3502H2.64104C1.91399 76.3502 1.32471 75.7609 1.32471 75.0338V56.6044C1.32471 55.8775 1.91399 55.2881 2.64104 55.2881Z" fill="#4CAF50" />
                <path d="M55.2964 14.4795C56.7505 14.4795 57.9292 13.3008 57.9292 11.8467C57.9292 10.3926 56.7505 9.21387 55.2964 9.21387C53.8423 9.21387 52.6636 10.3926 52.6636 11.8467C52.6636 13.3008 53.8423 14.4795 55.2964 14.4795Z" fill="white" />
                <path d="M52.6636 25.0106V32.9089H50.0308V38.1745H60.5619V32.9089H57.9291V19.7451H50.0308V25.0106H52.6636Z" fill="white" />
                <path d="M77.6748 23.694C77.6752 29.6293 75.3178 35.3217 71.1209 39.5186C66.924 43.7155 61.2317 46.0731 55.2964 46.0724C54.625 46.0724 53.9537 46.0461 53.2954 45.9804C64.8498 44.9738 73.7188 35.3041 73.7258 23.7061C73.7327 12.108 64.8748 2.42784 53.3216 1.4077C53.9779 1.34352 54.6369 1.31283 55.2962 1.3156C61.2315 1.31514 66.9238 3.6726 71.1207 7.8695C75.3176 12.0664 77.6752 17.7587 77.6748 23.694Z" fill="#1566C0" />
                <path d="M2.64112 77.6666H13.1722C13.9581 77.6637 14.7008 77.3079 15.1957 76.6975C17.5803 78.3267 20.4333 79.1295 23.3176 78.9831H51.9091C53.5665 78.9772 55.1883 78.5024 56.5872 77.6134L76.968 64.5517C77.9826 63.8836 78.6822 62.832 78.9066 61.6381C79.1439 60.4054 78.8696 59.1293 78.1469 58.1029C77.055 56.572 75.0892 55.9372 73.3081 56.5403L53.1767 63.6036C52.8822 63.7067 52.5721 63.758 52.2601 63.7552H51.9464C52.3489 62.3231 52.1643 60.7895 51.4335 59.4937L51.3306 59.3074C50.1425 57.2135 47.9274 55.9126 45.52 55.8949H39.8329C37.872 55.8812 35.9781 55.1799 34.4812 53.9132C32.5075 52.2591 30.0168 51.3483 27.4416 51.3389H26.7744C26.4431 51.3389 19.6944 51.4004 15.339 55.1143C14.8491 54.3998 14.0386 53.9725 13.1722 53.9715H2.64112C1.1878 53.9732 0.00999768 55.1509 0.00830078 56.6044V75.0338C0.00999768 76.4871 1.1878 77.6649 2.64112 77.6666ZM26.7743 53.9717H27.4415C29.3999 53.9806 31.2931 54.675 32.7932 55.9341C34.7638 57.5961 37.255 58.5138 39.8327 58.5277H45.52C46.9776 58.5305 48.3203 59.3195 49.0321 60.5916L49.135 60.778C49.6552 61.7088 49.6299 62.8485 49.0688 63.7552H42.5891C40.3756 63.7616 38.2177 64.4502 36.4096 65.7272L35.6202 66.2813C35.2354 66.5514 35.0239 67.0062 35.0652 67.4745C35.1067 67.9429 35.3947 68.3535 35.8211 68.5518C36.2475 68.75 36.7471 68.7059 37.132 68.4358L37.9188 67.8843C39.2848 66.9173 40.9155 66.3949 42.5891 66.3879H52.2602C52.8749 66.3908 53.4857 66.2873 54.0651 66.082L74.167 59.0291C74.8463 58.7983 75.5961 59.0454 76.0054 59.6346C76.311 60.073 76.4252 60.6169 76.3216 61.1412C76.2368 61.6301 75.9553 62.0627 75.5426 62.338L55.1759 75.3906C54.1993 76.0121 53.067 76.3447 51.9094 76.3503H23.3176C18.2847 76.3503 16.3255 74.3712 15.8049 73.6988V58.4261C19.0201 54.0417 26.6972 53.9717 26.7743 53.9717ZM2.64112 56.6044H13.1722V75.0338H2.64112V56.6044Z" fill="black" />
                <path d="M5.65555 68.8333C5.27575 69.2098 5.16175 69.7788 5.36677 70.2727C5.57179 70.7667 6.0554 71.0874 6.59024 71.0843C6.93872 71.0789 7.27223 70.9427 7.52492 70.7025C7.64108 70.5745 7.73456 70.4274 7.80136 70.2679C7.86877 70.1097 7.90456 69.9398 7.90672 69.7678C7.90163 69.4193 7.76526 69.0857 7.52492 68.8331C6.99703 68.346 6.18344 68.346 5.65555 68.8333Z" fill="black" />
                <path d="M55.2963 15.7967C57.4773 15.7967 59.2455 14.0286 59.2455 11.8476C59.2455 9.66661 57.4773 7.89844 55.2963 7.89844C53.1153 7.89844 51.3472 9.66661 51.3472 11.8476C51.3496 14.0276 53.1163 15.7944 55.2963 15.7967ZM55.2963 10.5313C56.0234 10.5313 56.6127 11.1205 56.6127 11.8476C56.6127 12.5746 56.0234 13.1639 55.2963 13.1639C54.5693 13.1639 53.98 12.5745 53.98 11.8476C53.9808 11.1209 54.5696 10.5319 55.2963 10.5313Z" fill="black" />
                <path d="M60.562 31.5936H59.2456V19.7462C59.2458 19.3971 59.1071 19.0622 58.8601 18.8152C58.6132 18.5682 58.2784 18.4297 57.9291 18.4297H50.0308C49.6817 18.4295 49.3468 18.5682 49.0999 18.8152C48.853 19.062 48.7144 19.3969 48.7144 19.7462V25.0117C48.7142 25.3608 48.8529 25.6957 49.0999 25.9426C49.3467 26.1895 49.6816 26.3281 50.0308 26.3281H51.3472V31.5936H50.0308C49.6817 31.5935 49.3468 31.7322 49.0999 31.9791C48.853 32.226 48.7144 32.5609 48.7144 32.9101V38.1756C48.7142 38.5247 48.8529 38.8596 49.0999 39.1066C49.3467 39.3534 49.6816 39.4921 50.0308 39.4921H60.562C60.9111 39.4922 61.246 39.3536 61.4929 39.1066C61.7398 38.8598 61.8785 38.5249 61.8785 38.1756V32.9101C61.8786 32.561 61.7399 32.2261 61.4929 31.9791C61.246 31.7322 60.9111 31.5935 60.562 31.5936ZM59.2455 36.8591H51.3472V34.2263H52.6635C53.0126 34.2264 53.3475 34.0878 53.5945 33.8408C53.8413 33.594 53.98 33.2591 53.98 32.9098V25.0115C53.9801 24.6624 53.8415 24.3275 53.5945 24.0805C53.3477 23.8337 53.0128 23.695 52.6635 23.695H51.3472V21.0622H56.6127V32.9097C56.6125 33.2588 56.7512 33.5937 56.9982 33.8406C57.245 34.0875 57.5799 34.2261 57.9291 34.2261H59.2455V36.8591Z" fill="black" />
                <path d="M55.2964 7.08349e-07C45.1592 -0.00246751 36.1438 6.4456 32.8705 16.0399C29.5972 25.6342 32.7914 36.2478 40.8162 42.4418V50.0226C40.8162 50.5275 41.1049 50.988 41.5596 51.2077C42.0142 51.4275 42.5544 51.3678 42.9501 51.0542L48.7401 46.4681C57.6405 49.03 67.2176 46.1718 73.258 39.1507C79.2983 32.1297 80.6947 22.2333 76.8326 13.8151C72.9704 5.39692 64.5583 0.000617763 55.2964 7.08349e-07ZM55.2964 44.757C53.1147 44.7605 50.9457 44.4222 48.8687 43.7543C48.4497 43.6204 47.9917 43.7032 47.6462 43.9755L43.449 47.3005V41.7823C43.4491 41.3604 43.2469 40.9641 42.9052 40.7167C34.5392 34.6179 31.8493 23.3413 36.5617 14.1228C41.2742 4.90435 51.9898 0.480532 61.8329 3.69014C71.676 6.89975 77.7244 16.7898 76.0969 27.014C74.4694 37.2383 65.6495 44.7621 55.2964 44.757Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_22_98">
                    <rect width="79" height="79" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}