.login-form {
  margin-top: 200px;
  margin-bottom: 200px;

  max-width: 546px;
  margin-left: auto;
  margin-right: auto;

  background: url(../../../static/img/d3892778b951dc89d2a44d20f3c7467e.jpg) lightgray 50% / cover no-repeat;

  padding: 55px 66px;
}

.login-form form {
  border-radius: 46px;
  background: rgba(217, 217, 217, 0.53);

  /* Blur/Glass */
  backdrop-filter: blur(calc(var(--sds-size-blur-100) / 2));

  display: flex;
  flex-direction: column;
  /* align-items: center; */

  padding: 84px 33px 0px 33px;

}

.login-form form .form-group {
  position: relative;
}

.form-group .icon {
  position: absolute;
  top: 0px;

  width: 57px;
  height: 53px;
  flex-shrink: 0;
  background: #D9D9D9;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form form input {
  border-radius: 0px 30px 0px 0px;
  background: rgba(217, 217, 217, 0.21);
  border: none;
  padding: 14px 14px 14px 75px;

  color: #FFF;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  outline: 0px;

  width: 100%;
}

.login-form form input[type=email] {
  margin-bottom: 45px;
}

.forgot-password {
  margin-top: 9px;

  display: flex;
  justify-content: flex-end;
}

.forgot-password a {
  color: #FFF;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login-now {
  margin-top: 76px;

  display: flex;
  justify-content: center;
}

.login-now button {
  border-radius: 30px 30px 0px 0px;
  background: rgba(255, 255, 255, 0.21);
  border: none;
  padding: 12px 52px;

  color: #FFF;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}