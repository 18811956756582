import React, { useEffect, useState } from "react";
import { StarIcon } from "../StarIcon/StarIcon";

export const InputStars = ({ starWidth = 26, defaultValue = 0, onChange }) => {
  const [value, setValue] = useState(0);

  const onMouseEnter = (value) => {
    setValue(value);
  }

  const onMouseLeave = () => {
    setValue(defaultValue);
  }

  const onClick = (value) => {
    setValue(value);
    onChange?.(value);
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [setValue, defaultValue])

  return (
    <>
      <StarIcon onMouseLeave={onMouseLeave} onMouseEnter={() => onMouseEnter(1)} onClick={() => onClick(1)} color={value >= 1 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon onMouseLeave={onMouseLeave} onMouseEnter={() => onMouseEnter(2)} onClick={() => onClick(2)} color={value >= 2 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon onMouseLeave={onMouseLeave} onMouseEnter={() => onMouseEnter(3)} onClick={() => onClick(3)} color={value >= 3 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon onMouseLeave={onMouseLeave} onMouseEnter={() => onMouseEnter(4)} onClick={() => onClick(4)} color={value >= 4 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon onMouseLeave={onMouseLeave} onMouseEnter={() => onMouseEnter(5)} onClick={() => onClick(5)} color={value >= 5 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
    </>
  );
};
