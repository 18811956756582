.topnav {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 145px;
  padding-right: 145px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topnav .logo img {
  width: 106px;
}

.topnav .links {
  display: flex;
  gap: 150px;
}

.topnav .links a {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}