export const KeyFeature3 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79" fill="none">
            <g clip-path="url(#clip0_22_83)">
                <path d="M0 10.8966V4.08621C0 1.82946 1.82946 0 4.08621 0H42.2241C44.4809 0 46.3103 1.82946 46.3103 4.08621V10.8966L43.5862 13.6207H1.36207L0 10.8966Z" fill="#CCD0DA" />
                <path d="M0 57.2068V64.0171C0 66.2739 1.82946 68.1033 4.08621 68.1033H42.2241C44.4809 68.1033 46.3103 66.2739 46.3103 64.0171V57.2068L43.5862 55.8447H1.36207L0 57.2068Z" fill="#CCD0DA" />
                <path d="M0 10.8965H46.3103V57.2068H0V10.8965Z" fill="#2783EC" />
                <path d="M61.2928 78.9997C71.0721 78.9997 78.9997 71.0721 78.9997 61.2928C78.9997 51.5136 71.0721 43.5859 61.2928 43.5859C51.5136 43.5859 43.5859 51.5136 43.5859 61.2928C43.5859 71.0721 51.5136 78.9997 61.2928 78.9997Z" fill="#31B791" />
                <path d="M23.1554 50.3967C32.1824 50.3967 39.5002 43.0789 39.5002 34.0519C39.5002 25.0249 32.1824 17.707 23.1554 17.707C14.1284 17.707 6.81055 25.0249 6.81055 34.0519C6.81055 43.0789 14.1284 50.3967 23.1554 50.3967Z" fill="#FCC10A" />
                <path d="M29.9653 6.81008H28.6033C27.851 6.81008 27.2412 6.20026 27.2412 5.44801C27.2412 4.69576 27.851 4.08594 28.6033 4.08594H29.9653C30.7176 4.08594 31.3274 4.69576 31.3274 5.44801C31.3274 6.20026 30.7176 6.81008 29.9653 6.81008Z" fill="#8991A0" />
                <path d="M23.1548 6.81008H16.3445C15.5922 6.81008 14.9824 6.20026 14.9824 5.44801C14.9824 4.69576 15.5922 4.08594 16.3445 4.08594H23.1548C23.9071 4.08594 24.5169 4.69576 24.5169 5.44801C24.5169 6.20026 23.9071 6.81008 23.1548 6.81008Z" fill="#8991A0" />
                <path d="M25.8787 64.0171H20.4304C19.6782 64.0171 19.0684 63.4073 19.0684 62.655C19.0684 61.9028 19.6782 61.293 20.4304 61.293H25.8787C26.631 61.293 27.2408 61.9028 27.2408 62.655C27.2408 63.4073 26.631 64.0171 25.8787 64.0171Z" fill="#8991A0" />
                <path d="M58.5692 70.8272C58.208 70.8272 57.8616 70.6836 57.6063 70.4281L53.52 66.3419C53.0039 65.8075 53.0112 64.958 53.5366 64.4326C54.062 63.9072 54.9116 63.8998 55.446 64.416L58.2832 67.2532L66.9269 52.4393C67.1539 51.9872 67.6128 51.6982 68.1186 51.6887C68.6244 51.6792 69.0938 51.9509 69.3376 52.3941C69.5814 52.8374 69.5594 53.3793 69.2805 53.8014L59.7461 70.1462C59.5331 70.5113 59.1628 70.7569 58.7436 70.8109C58.6858 70.8198 58.6276 70.8252 58.5692 70.8272Z" fill="#F0F2F5" />
                <path d="M23.1547 43.5858C16.7979 43.5858 15.1185 38.7736 15.0531 38.5679C14.8994 38.1056 15.0039 37.5964 15.3274 37.2321C15.6508 36.8678 16.1441 36.7037 16.6213 36.8017C17.0986 36.8996 17.4873 37.2448 17.6411 37.7071C17.6778 37.8242 18.8124 40.8616 23.1547 40.8616C27.5705 40.8616 28.6629 37.7384 28.6738 37.7071C28.9115 36.9924 29.6836 36.6058 30.3982 36.8435C31.1128 37.0812 31.4995 37.8533 31.2618 38.5679C31.1909 38.7736 29.5115 43.5858 23.1547 43.5858Z" fill="#F28F02" />
                <path d="M17.7068 31.3272C16.9545 31.3272 16.3447 30.7174 16.3447 29.9651V27.241C16.3447 26.4887 16.9545 25.8789 17.7068 25.8789C18.459 25.8789 19.0689 26.4887 19.0689 27.241V29.9651C19.0689 30.7174 18.459 31.3272 17.7068 31.3272Z" fill="#F28F02" />
                <path d="M28.6033 31.3272C27.851 31.3272 27.2412 30.7174 27.2412 29.9651V27.241C27.2412 26.4887 27.851 25.8789 28.6033 25.8789C29.3555 25.8789 29.9653 26.4887 29.9653 27.241V29.9651C29.9653 30.7174 29.3555 31.3272 28.6033 31.3272Z" fill="#F28F02" />
            </g>
            <defs>
                <clipPath id="clip0_22_83">
                    <rect width="79" height="79" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}