.sticky-banner {
  position: absolute;
  top: 0px;
  left: 0px;

  z-index: -1;
}

.sticky-banner img {
  width: 100%;
}

.banner {
  display: flex;
  padding-left: 145px;

  position: relative;

  margin-top: 51px;
}

.banner h1 {
  color: #FFF;
  font-family: "Josefin Sans";
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;

  margin-top: 54px;
}

.banner h1 span {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.banner p {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */

  white-space: nowrap;

  margin-top: 43px;
}

.banner .right {
  position: absolute;
  right: 0px;
}

.banner img {
  width: 677px;

}

.send-message {
  max-width: 784px;
  margin: auto;
  padding-top: 320px;
  padding-bottom: 224px;
}

.send-message h2 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.send-message form {
  display: flex;
  flex-wrap: wrap;
  row-gap: 56px;
  column-gap: 30px;
  margin-top: 61px;

  justify-content: flex-end;
}

.send-message form input,
.send-message form textarea {
  color: rgba(0, 0, 0, 0.41);
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  outline: 0px;
  padding: 20px 17px;

  flex: 1;
  resize: none;

  border: none;
  border-radius: 0px 30px 0px 0px;
  background: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
}

.send-message form .block-input {
  flex: 0 0 100%;
}

.send-message form button {
  color: #FFF;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  background-image: url('../../../static/img/Rectangle 3368.png');
  background-repeat: no-repeat;
  background-size: cover;

  padding: 37px 36px 26px 118px;
  border-width: 0px;

  background-color: transparent
    /* justify-self: flex-end; */
}

.send-message .for-queries {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 159px;
}

.send-message .for-queries h2 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.send-message .for-queries div {
  display: flex;
  align-items: center;
  gap: 23px;
}

.send-message .for-queries span {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .send-message form input {
  padding: 20px 17px;

} */