import clsx from "clsx";
import styles from "./HomePageReviewCard.module.css";
import { getUserAvatar, getUserShortName, getUserSlug } from "../../utils";
import { Link } from "react-router-dom";
import { ReviewStars } from "../ReviewStars/ReviewStars";

export const HomePageReviewCard = ({ review, position = 'left' }) => {
    return (
        <div className={clsx(styles['review'], styles[`review-${position}`])}>
            <div className={styles['inner-container']}>
                <div style={{ backgroundImage: `url('${getUserAvatar(review.author, false)}')` }} className={styles['user-avatar']}></div>
                <div className={styles['user-details']}>
                    <Link className={styles['user-name']} to={`/user/${getUserSlug(review.author)}`}>
                        <div>{getUserShortName(review.author)}</div>
                    </Link>
                    <Link className={styles['user-company']} to={`/company/${review.company.slug}`}>
                        <div>{review.company.name}</div>
                    </Link>
                </div>
                <div className={styles['slant-background']}> </div>
                <div className={styles['content']}>
                    {review.review}
                </div>
                <div className={styles['quotation-mark']}></div>
                <div className={styles['review-stars']}>
                    <ReviewStars rating={review.rating} />
                </div>
            </div>
        </div>
    );
}