import React from "react";
import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { Homepage } from "./screens/Homepage";
import Guest from "./layouts/Guest";
import { ContactUsPage } from "./screens/ContactUsPage";
import { AboutPage } from "./screens/AboutPage";
import { CompanyPage } from "./screens/CompanyPage";
import { UserPage } from "./screens/UserPage";
import { LoginPage } from "./screens/LoginPage";
import { SignupPage } from "./screens/SignupPage";
import { ForgotPasswordPage } from "./screens/ForgotPasswordPage";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <Routes>
      <Route path="/" element={<Guest Component={Homepage} />} />
      <Route path="/about" element={<Guest Component={AboutPage} />} />
      <Route path="/company/:slug" element={<Guest Component={CompanyPage} />} />
      <Route path="/contact-us" element={<Guest Component={ContactUsPage} />} />
      <Route path="/user/:slug" element={<Guest Component={UserPage} />} />
      <Route path="/login" element={<Guest Component={LoginPage} />} />
      <Route path="/signup" element={<Guest Component={SignupPage} />} />
      <Route path="/forgot-password" element={<Guest Component={ForgotPasswordPage} />} />
    </Routes>
  );
}

export default App;
