.review-row {
  padding-left: 524px;
  position: relative;
}

.review-row .review-company {
  border-radius: 200%;
  background: rgba(255, 188, 148, 0.29);

  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-align: center;

  width: 561px;
  height: 93px;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 31px;
}

.review-row .review {
  position: relative;
  width: 770px;

  display: flex;

  padding-left: 241px;
}

.review-row .review .user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  left: 0px;

  min-width: 200px;

}

.review-row .review .user-avatar {
  width: 154.554px;
  height: 154.554px;
  flex-shrink: 0;
  border-radius: 100% 100% 100% 0px;

  /* background-image: url(../../../static/img/b1cb49125e6afb2afa495f719730af57.jpg); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: absolute;
  left: -88.864px;
  top: 3.48px;
}

.review-row .review .user-details {
  background: #EDF6FF;
  padding: 5px 64px;
  display: flex;
  flex-direction: column;

  text-align: center;

  position: absolute;
  top: calc(154.554px + 3.48px - 5.44px);
  left: 0px;
  transform: translateX(calc(-1 * (100% - 39.179px)));
  /* right: 370px;
  width: 150px; */
  /* width: max-content; */
}

.review-row .review .user-name {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 153.562%;
  /* 46.069px */
}

.review-row .review .user-company {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  /* 30.712px */
}

.review-row .review .inner-container {
  /* background-repeat: no-repeat; */
  /* background-image: url('../../../static/img/Rectangle 3366.png'); */
  width: 406px;

  padding-top: 30.61px;
  padding-bottom: 118.16px;

  padding-left: 116px;
  padding-right: 42px;

  position: relative;

  min-height: 360px;
  max-height: 500px;
}

.review-row .review .inner-container .content {
  z-index: 1;
  position: relative;
}

.review-row .review .inner-container .content h3 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  text-align: center;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  /* max-height: 318px; */
  overflow: hidden;
}


.review-row .review .inner-container .content p {
  color: #000;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  margin-top: 25.37px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 10;
  /* max-height: 318px; */
  overflow: hidden;
}

.review-row .review .quotation-mark {
  position: absolute;
  width: 74.366px;
  height: 74.366px;
  transform: rotate(-178.797deg);
  flex-shrink: 0;
  background-image: url(../../../static/img/f200954fad573c99d2abe5b05c871ba0.png);
  background-size: cover;
  background-repeat: no-repeat;
  top: -25px;
  left: 65.69px;
}

.review-row .review .review-date {
  border-radius: 0px 25px 25px 0px;
  background: #EDF6FF;

  color: #000;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  padding: 14.15px 29.15px 14.15px 15.24px;

  position: absolute;
  right: -159.383px;
  top: calc(18.5px + 3.48px);
}

.review-row .review .review-stars {
  border-radius: 0px 25px 25px 0px;
  background: #EDF6FF;

  display: flex;
  gap: 4px;

  padding: 11.5px 14px;

  position: absolute;
  right: -8.71px;
  bottom: 28.3px;
}

.review-row .review .like-btn {
  border-radius: 100%;
  background: #EDF6FF;
  padding: 19.27px;

  display: flex;
  gap: 4px;

  padding: 11.5px 14px;

  position: absolute;
  left: 45.42px;
  bottom: 28.3px;

  cursor: pointer;
}

.review-row .review .slant-background {
  /* width: 406px;
  height: 342px; */
  flex-shrink: 0;

  background-color: #EDF6FF;
  /* background-color: #EDF6FF; */
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
  border-radius: 50px 50px 100% 100%;
  transform: rotate(-5deg);

  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  z-index: 0;
}
