import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getLandingDataAsync } from "../../actions/general.action";
import styles from "./AboutPage.module.css";
import clsx from "clsx";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";

export const AboutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandingDataAsync(() => {
      console.log("Homepage data loaded")
    }))
  }, []);

  useEffect(() => {
    document.title = "CloudReputation - Elevating Reviews, Elevating Choices";
  }, []);

  return (
    <>
      <div className={styles['sticky-banner']}>
        <img src="/img/about-banner.png" alt="About Banner" />
      </div>

      <TopNav />

      <div className={clsx(styles['banner'])}>
        <div className={styles['left']}>

          <h1>
            About Us
          </h1>
          <p>
            Our platform is designed to help users make well-informed decisions by providing honest, unbiased reviews of cloud services.
          </p>
          <button className={styles['ellipse']}>
            Share your Experiences
          </button>
        </div>
        <div className={styles['right']}>
          <img src="/img/about-banner-image.png" alt="" />
        </div>
      </div>

      <div className={styles['our-mission']}>
        <div className={styles['left']}>
          <img src="/img/our-mission-image.png" alt="Our Mission" />
        </div>
        <div className={styles['right']}>
          <h2>Our Mission</h2>
          <p>
            To empower you with the knowledge needed to navigate the complex landscape of cloud services. With countless platforms and providers available, it can be overwhelming to determine which solution is right for your needs.
            <br /><br />
            Our team of experts evaluates and reviews each service based on key factors like performance, security, scalability, user experience, and cost-effectiveness, providing you with clear and actionable insights.
          </p>
        </div>
        <div className={styles['floated-design']}>
          <img src="/img/our-mission-vector.png" alt="Float" />
        </div>
      </div>

      <div className={styles['stats']}>
        <div>
          <div className={styles['value']}>15,000+</div>
          <div className={styles['property']}>Customers Helped</div>
        </div>
        <div>
          <div className={styles['value']}>10 lakh+</div>
          <div className={styles['property']}>Customers Reviewed</div>
        </div>
        <div>
          <div className={styles['value']}>5 Lakh+</div>
          <div className={styles['property']}>Businesses Reviewed</div>
        </div>
      </div>

      <div className={styles['why-trust-us']}>
        <div className={styles['left']}>
          <h2>Why Trust Us?</h2>
          <p>
            To empower you with the knowledge needed to navigate the complex landscape of cloud services. With countless platforms and providers available, it can be overwhelming to determine which solution is right for your needs.
            <br /><br />
            Our team of experts evaluates and reviews each service based on key factors like performance, security, scalability, user experience, and cost-effectiveness, providing you with clear and actionable insights.
          </p>
        </div>
        <div className={styles['right']}>
          <img src="/img/why-trust-us-image.png" alt="Why Trust Us" />
        </div>

        <div className={styles['floated-design']}>
          <img src="/img/why-trust-us-vector.png" alt="Float" />
        </div>
      </div>

      <Footer />
    </>
  );
};
