import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./ForgotPasswordPage.module.css";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";
import { resetPasswordAsync } from "../../actions/auth.action";
import { UserIcon } from "../../components/UserIcon/UserIcon";

export const ForgotPasswordPage = () => {
  const initialState = {
    email: '',
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(resetPasswordAsync(state, () => {
      setState(initialState)
    }))
  }

  useEffect(() => {
    document.title = "CloudReputation - Forgot Password";
  }, []);

  return (
    <>

      <TopNav bg="filled" />

      <div className={styles['login-form']}>

        <form>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <UserIcon />
            </div>
            <input type="email" name="email" value={state.email} onChange={onChange} placeholder="Email ID" />
          </div>

          <div className={styles['login-now']}>
            <button onClick={onSubmit}>Reset Passowrd</button>
          </div>



        </form>
      </div>

      <Footer />
    </>
  );
};
