import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./UserPage.module.css";
import { LocationIcon } from "../../components/LocationIcon/LocationIcon";
import { useNavigate, useParams } from "react-router-dom";
import { getUserIdFromSlug, getUserLongName } from "../../utils";
import { getUserByIdAsync } from "../../actions/user.action";
import { getUserReviewsAsync } from "../../actions/review.action";
import { UserPageReviewCard } from "../../components/UserPageReviewCard/UserPageReviewCard";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";

export const UserPage = () => {
  const initialState = {
    user: null,
    reviews: [],
    last_page: 1,
    page: 1,
    offset: 0
  };
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [id, setId] = useState(null);

  useEffect(() => {
    try {
      setId(getUserIdFromSlug(slug))
    } catch (error) {
      navigate('/invalid-page')
      // go to 404 page
    }
  }, [slug])

  useEffect(() => {
    if (!id) return
    setLoaded(false);
    setState(initialState);
    dispatch(getUserByIdAsync(id, (data) => {
      setState({
        ...state,
        ...data,
      })
      setLoaded(true);
    }))
  }, [id])

  useEffect(() => {
    if (loaded)
      dispatch(getUserReviewsAsync(id, page, 20, (data) => {
        const { pagination: { data: reviews, last_page, page, offset, total } } = data;
        console.log(data)
        setState({
          ...state,
          reviews,
          last_page,
          page,
          offset,
          total,
        })
      }))
  }, [loaded, page])

  useEffect(() => {
    document.documentElement.classList.add('light-bg')
    return () => document.documentElement.classList.remove('light-bg')
  }, [])

  useEffect(() => {
    document.title = state.user ? `${getUserLongName(state.user)} Reviews` : "User Reviews";
  }, [state.user]);

  if (!state.user) return null

  return (
    <>
      <TopNav bg="filled" />

      <div className={styles['user']}>
        <div className={styles['avatar']}> </div>

        <div className={styles['details']}>
          <h1>{getUserLongName(state.user)}</h1>
          {
            state.country
              ?
              <div className={styles['location']}>
                <LocationIcon />
                <span>{state.country}</span>
              </div>
              :
              null
          }

          <div className={styles['reviews-count']}>17 Reviews</div>
        </div>
      </div>

      <div className={styles['contributions']}>
        <h2>Total Contributions</h2>

        <div className={styles['review-rows']}>
          {
            state.reviews.length > 0
              ?
              state.reviews.map((review, index) => (<UserPageReviewCard review={review} key={index} />))
              :
              <div className={styles['empty-list']}>No reviews yet</div>
          }
        </div>
      </div>

      <Footer />
    </>
  );
};
