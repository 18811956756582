.review {
  position: relative;
  width: 770px;

  display: flex;

  padding-left: 241px;
}

.review-right {
  align-self: flex-end;
}

.review .user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  left: 0px;

}

.review .user-avatar {
  width: 213px;
  height: 213px;
  flex-shrink: 0;
  border-radius: 100% 100% 100% 0px;

  /* background-image: url(../../../static/img/b1cb49125e6afb2afa495f719730af57.jpg); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: absolute;
  left: -106px;
  top: -49px;
}

.review .user-details {
  background: #EDF6FF;
  padding: 5px 20px 5px 20px;
  display: flex;
  flex-direction: column;

  text-align: center;

  min-width: 200px;

  position: absolute;
  top: 164px;
  left: 0px;
  transform: translateX(calc(-1 * (100% - 36px)));
}

.review .user-name {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 153.562%;

  width: 100%;
  text-align: center;
}

.review .user-company {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  max-width: 186px;
  width: 100%;
  text-align: center;
}

.review .inner-container {
  /* background-repeat: no-repeat; */
  /* background-image: url('../../../static/img/Rectangle 3366.png'); */
  width: 406px;

  padding-top: 106px;
  padding-bottom: 76px;

  padding-left: 116px;
  padding-right: 42px;
  /* padding-right: 24px; */

  position: relative;

  min-height: 360px;
  max-height: 500px;
}

.review .inner-container .content {
  color: #000;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 10;
  /* max-height: 318px; */
  overflow: hidden;
}

.review .quotation-mark {
  position: absolute;
  width: 68.325px;
  height: 68.325px;
  transform: rotate(-178.797deg);
  flex-shrink: 0;
  background-image: url(../../../static/img/f200954fad573c99d2abe5b05c871ba0.png);
  background-size: cover;
  background-repeat: no-repeat;
  top: -30px;
  left: 107px;
}

.review .review-stars {
  border-radius: 0px 25px 25px 0px;
  background: #EDF6FF;

  display: flex;
  gap: 4px;

  padding: 11.5px 14px;

  position: absolute;
  right: -113px;
  top: 7px;
}

.review .slant-background {
  /* width: 406px;
  height: 342px; */
  flex-shrink: 0;
  background-color: #EDF6FF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
  border-radius: 50px 50px 100% 100%;
  transform: rotate(-5deg);

  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  z-index: -1;
}