import clsx from "clsx";
import styles from "./UserPageReviewCard.module.css";
import { getUserAvatar, getUserShortName } from "../../utils";
import { LikeIcon } from "../LikeIcon/LikeIcon";
import { useState } from "react";
import { ReviewStars } from "../ReviewStars/ReviewStars";

export const UserPageReviewCard = ({ review }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleClick = () => {
        setIsChecked(!isChecked)
    }

    const date = new Date(review.created_at);

    const formattedDate = date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    });

    return (
        <div className={styles['review-row']}>
            <div className={styles['review-company']}>Insights on {review.company.name}</div>

            <div className={clsx(styles['review'])}>
                <div className={styles['inner-container']}>
                    <div style={{ backgroundImage: `url('${getUserAvatar(review.author, false)}')` }} className={styles['user-avatar']}></div>
                    <div className={styles['user-details']}>
                        <div className={styles['user-name']}>{getUserShortName(review.author)}</div>
                        {/* <div className={styles['user-company']}>{review.company.name}</div> */}
                    </div>
                    <div className={styles['slant-background']}> </div>
                    <div className={styles['content']}>
                        <h3>{review.title}</h3>
                        <p>{review.review}</p>
                    </div>
                    <div className={styles['quotation-mark']}></div>
                    <div className={styles['review-date']}>
                        posted on {formattedDate}
                    </div>

                    <div className={styles['review-stars']}>
                        <ReviewStars rating={review.rating} />
                    </div>

                    <div className={styles['like-btn']} onClick={handleClick}>
                        <LikeIcon />
                    </div>
                </div>
            </div>
        </div>
    );
}