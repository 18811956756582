.footer {
  background: #FFF;
  padding: 55px 210px 98px 210px;

  position: relative;

  /* overflow: hidden; */

  /* margin-top: 200px; */
}

.footer-floater {
  position: absolute;
  top: -44.25px;
  right: 210px;
  left: 210px;

  background: linear-gradient(90deg, #FF948D 0%, #FFBD92 100%);
  padding: 24px 33px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
}

.footer .footer-floater h4 {
  color: #FFF;
  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer .footer-floater button {
  border-radius: 12px;
  border: 1px solid #F28F02;
  background: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  padding: 10px 34px;
}

.footer .footer-columns {
  display: flex;
  justify-content: space-between;
}

.footer .footer-column {
  display: flex;
  flex-direction: column;
}

.footer .footer-column h5 {
  padding: 36px 0px;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer .footer-column .links {
  margin-top: 17px;

  display: flex;
  flex-direction: column;
  gap: 22px;
}

.footer .connect-column .links {
  align-items: center;
  gap: 7px;
}

.footer .footer-column .links a {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer .footer-column .links a img {
  width: 55px;
}

.footer .about-column img {
  width: 134px;
}

.footer .about-column div {
  color: #000;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 38px;
}

.copyright {
  overflow: hidden;

  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;

  height: 50px;

  /* position: absolute;
  left: 0px;
  bottom: 0px; */

  /* width: 100%; */
}

.copyright div {
  border-radius: 200% 200% 0px 0px;
  padding: 22px 0px 66px 0px;
  background: linear-gradient(90deg, #FF958D 0%, #FFBC92 100%);
  
  
  text-align: center;
  color: #000;
  font-family: "Josefin Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  position: absolute;
  left: 0px;
  top: 0px;

  width: 100%;
}