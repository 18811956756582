import clsx from "clsx";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <footer className={styles['footer']}>
            <div className={styles['footer-floater']}>
                <h4>We're all ears! Reach out with anything on your mind.</h4>
                <button>Contact Us</button>
            </div>
            <div className={styles['footer-columns']}>
                <div className={clsx(styles['footer-column'], styles['about-column'])}>
                    <img src="/img/logo.png" alt="" />
                    <div>
                        Read real reviews, share your<br />
                        thoughts, and make confident <br />
                        decisions with insights from our <br />
                        community.
                    </div>
                </div>

                <div className={clsx(styles['footer-column'], styles['link-column'])}>
                    <h5>Quick Links</h5>

                    <div className={styles['links']}>
                        <Link to="/">Home</Link>
                        <Link to="/about">About Us</Link>
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                </div>

                <div className={clsx(styles['footer-column'], styles['link-column'])}>
                    <h5>Newsletter</h5>

                    <div className={styles['links']}>
                        <Link to="/login">Login</Link>
                        <Link to="/signup">Sign Up</Link>
                    </div>
                </div>

                <div className={clsx(styles['footer-column'], styles['connect-column'])}>
                    <h5>Connect us on</h5>

                    <div className={styles['links']}>
                        <a href="#">
                            <img src="/img/f34899d64dc38fc6faf5ba98c40c125c.png" alt="Facebook" />
                        </a>
                        <a href="#">
                            <img src="/img/d0bfb871a0d559ff5687e6c402766395.png" alt="Youtube" />
                        </a>
                        <a href="#">
                            <img src="/img/eab137b5a5aeccfb1d36c97b15c3bd83.png" alt="Linkedin" />
                        </a>
                        <a href="#">
                            <img src="/img/fd5127e6b2946ec1a2efd551df4f929c.png" alt="Instagram" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}