import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyAsync } from "../../actions/company.action";
import styles from "./CompanyPage.module.css";
import { LogoBackdrop } from "../../components/LogoBackdrop/LogoBackdrop";
import { PinIcon } from "../../components/PinIcon/PinIcon";
import { FilterIcon } from "../../components/FilterIcon/FilterIcon";
import Select from 'react-select'
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyReviewsAsync, postReviewsAsync, updateReviewsAsync } from "../../actions/review.action";
import { showToast } from "../../utils";
import { CompanyPageReviewCard } from "../../components/CompanyPageReviewCard/CompanyPageReviewCard";
import { ReviewStars } from "../../components/ReviewStars/ReviewStars";
import { InputStars } from "../../components/InputStars/InputStars";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";

export const CompanyPage = () => {
  const initialState = {
    company: null,
    rating_counts: {},
    reviews: [],
    last_page: 1,
    page: 1,
    offset: 0,
    highlighted: null
  };
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState({ value: 'alphabetical', label: 'Alphabetical' });

  const options = [
    { value: 'alphabetical', label: 'Alphabetical' },
    { value: 'most_reviewed', label: 'Most Reviewed' },
    { value: 'recent_activity', label: 'Recent Activity' }
  ]

  const [updateTrigger, triggerUpdate] = useState(1)

  const { profile } = useSelector(({ User }) => User);

  const initialFormState = {
    title: "",
    review: "",
    rating: 0,
  };
  const [values, setValues] = useState(initialFormState);

  const onRatingChange = (rating) => {
    if (!profile) return navigate('/login')
    setValues({
      ...values,
      rating: rating
    })
  }

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    })
  }

  const onSubmit = useCallback((event) => {
    if (!profile) return navigate('/login')
    event.preventDefault();
    if (!state.user_review?.id) {
      dispatch(
        postReviewsAsync(
          {
            ...values,
            company_id: state.company.id
          },
          (data) => {
            // setValues(initialFormState);
            showToast("Review submitted successfully", "success")
            triggerUpdate((number) => number + 1);
          },
          (error) => console.log("Error:", error),
        )
      )
    } else {
      dispatch(
        updateReviewsAsync(
          state.user_review?.id,
          {
            ...values,
            company_id: state.company.id
          },
          (data) => {
            // setValues(initialFormState);
            showToast("Review updated successfully", "success")
            triggerUpdate((number) => number + 1);
          },
          (error) => console.log("Error:", error),
        )
      )
    }

  }, [state.user_review, state.company, values, profile])

  useEffect(() => {
    setLoaded(false);
    setState(initialState);
    dispatch(getCompanyAsync(slug, false, (data) => {
      setState({
        ...state,
        ...data,
      })
      if (data.user_review) {
        const { title, rating, review } = data.user_review
        setValues({ title, review, rating, })
      }
      setLoaded(true);
    }))
  }, [slug, updateTrigger])

  useEffect(() => {
    if (loaded)
      dispatch(getCompanyReviewsAsync(slug, '', page, 20, (data) => {
        const { highlighted, pagination: { data: reviews, last_page, page, offset } } = data;
        setState({
          ...state,
          reviews,
          last_page,
          page,
          offset,
          highlighted
        })
      }))
  }, [loaded, page])

  useEffect(() => {
    document.documentElement.classList.add('light-bg')
    return () => document.documentElement.classList.remove('light-bg')
  }, [])

  useEffect(() => {
    document.title = state.company ? `${state.company.name} Reviews | Read Customer Reviews of ${state.company.website.replace("https://", "").replace("http://", "")}` : "Company Reviews";
  }, [state.company]);

  const getRatingPercentage = useCallback((number) => {
    const numberCount = state.rating_counts[`count_${number}`];
    const totalCount = state.rating_counts.review_count;
    const value = numberCount / totalCount * 100;
    return Math.round(value)
    // if (Math.round(value) === value) return value;
    // return value.toFixed(2);
  }, [state])

  if (!loaded) return null

  if (!state.company) return null

  // console.log(state)

  return (
    <>
      <TopNav bg="filled" />

      <div className={styles['company']}>
        <div>
          <LogoBackdrop />
        </div>

        <div className={styles['center']}>
          <div>
            <h1>{state.company.name}</h1>
            <div className={styles['review-summary']}>
              <div className={styles['review-rating']}>{state.company.avg_rating}</div>
              <div className={styles['review-stars']}>
                <ReviewStars rating={state.company.avg_rating} />
              </div>
              <div className={styles['review-count']}>
                ({state.rating_counts.review_count} reviews)
              </div>
            </div>
            <div className={styles['website']}>
              <span>Visit Site:</span>
              <a href={state.company.website} target="_blank"><span>{state.company.website}</span></a>

            </div>
          </div>
        </div>

        <div className={styles['top-tier']}>
          Top Tier
        </div>
      </div>


      <div className={styles['review-box']}>

        <div className={styles['write-review']}>

          <div className={styles['heading-box']}>
            <h2>Write a Review</h2>
            <div>
              <InputStars defaultValue={values.rating} onChange={onRatingChange} />
            </div>
          </div>

          <input type="text" name="title" onChange={onChange} value={values.title} placeholder="Title" />

          <textarea rows={1} name="review" onChange={onChange} value={values.review} placeholder="Review"></textarea>

          <button onClick={onSubmit}>Submit Review</button>
        </div>

        <div className={styles['review-stats']}>
          <h3>Ratings</h3>

          <div className={styles['review-rows']}>
            <div className={styles['review-row']}>
              <span className={styles['review-number']}>5</span>
              <span className={styles['review-stars']}>
                <ReviewStars rating={5} />
              </span>
              <span className={styles['review-percent']}>
                {getRatingPercentage(5)}%
              </span>
            </div>

            <div className={styles['review-row']}>
              <span className={styles['review-number']}>4</span>
              <span className={styles['review-stars']}>
                <ReviewStars rating={4} />
              </span>
              <span className={styles['review-percent']}>
                {getRatingPercentage(4)}%
              </span>
            </div>

            <div className={styles['review-row']}>
              <span className={styles['review-number']}>3</span>
              <span className={styles['review-stars']}>
                <ReviewStars rating={3} />
              </span>
              <span className={styles['review-percent']}>
                {getRatingPercentage(3)}%
              </span>
            </div>

            <div className={styles['review-row']}>
              <span className={styles['review-number']}>2</span>
              <span className={styles['review-stars']}>
                <ReviewStars rating={2} />
              </span>
              <span className={styles['review-percent']}>
                {getRatingPercentage(2)}%
              </span>
            </div>

            <div className={styles['review-row']}>
              <span className={styles['review-number']}>1</span>
              <span className={styles['review-stars']}>
                <ReviewStars rating={1} />
              </span>
              <span className={styles['review-percent']}>
                {getRatingPercentage(1)}%
              </span>
            </div>
          </div>

          <div className={styles['floating-pin']}>
            <PinIcon />
          </div>
        </div>

      </div>

      <div className={styles['reviews-container']}>
        <div className={styles['filter-and-list']}>
          <div className={styles['filter-box']}>
            <div className={styles['filter']}>
              <div>Filter</div>
              <div><FilterIcon /></div>
            </div>
            <div className={styles['sort']}>
              <span>Sort:</span>
              <Select value={sortBy} options={options} onChange={(event) => setSortBy(event)} className="category-sort-by" />
            </div>
          </div>

          <div className={styles['review-list']}>

            {
              state.reviews.map(review => (<CompanyPageReviewCard review={review} />))
            }

          </div>
        </div>

        <div className={styles['about-and-faq']}>
          <div className={styles['about']}>

            <div>
              <div className={styles['icon']}></div>
              <div className={styles['about-text']}>
                {state.company.description}
              </div>
            </div>

            <div>
              <h4>Contact</h4>
              <div className={styles['contact-text']}>
                {state.company.address}<br />
                Phone: {state.company.telephone}
              </div>
            </div>

          </div>

          <div className={styles['faq']}>
            <h3>
              How does Cloud Reputation.io
              ensure the authenticity of reviews?
            </h3>

            <div className={styles['faq-items']}>
              <div className={styles['faq-item']}>
                Cloud Reputation.io ensures review authenticity through AI algorithms, manual moderation, and community reporting to flag suspicious content.
              </div>

              <div className={styles['faq-item']}>
                We actively monitor reviews with automated systems that detect fake patterns and encourage community reporting of suspicious reviews.
              </div>

              <div className={styles['faq-item']}>
                Cloud Reputation.io screens all submissions using AI and human moderators to filter out fraudulent or biased reviews, ensuring a reliable experience.
              </div>

              <div className={styles['faq-item']}>
                Cloud Reputation.io combines tech and human oversight to detect patterns and remove fraudulent reviews, with community help.
              </div>

              <div className={styles['faq-item']}>
                Using AI and user reporting, Cloud Reputation.io ensures authentic reviews that reflect diverse customer experiences.
              </div>

              <div className={styles['faq-item']}>
                Cloud Reputation.io uses algorithms to spot suspicious patterns, cross-check reviews, and relies on moderators and community reporting to maintain trust
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
