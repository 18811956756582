import React from "react";
import { StarIcon } from "../StarIcon/StarIcon";

export const ReviewStars = ({ starWidth = 26, rating = 0 }) => {
  return (
    <>
      <StarIcon color={rating >= 1 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon color={rating >= 2 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon color={rating >= 3 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon color={rating >= 4 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
      <StarIcon color={rating >= 5 ? '#FF8A8C' : '#D9CACB'} width={starWidth} />
    </>
  );
};
