export const EnvelopeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <g clip-path="url(#clip0_147_48)">
                <rect width="50" height="50" fill="none" />
                <path d="M4.30841 11.4208L22.798 29.9125C23.345 30.4599 24.0756 30.7848 24.8484 30.8245C25.6212 30.8641 26.3812 30.6156 26.9813 30.1271L27.2188 29.9125L45.6959 11.4333C45.7556 11.6625 45.7959 11.8965 45.8168 12.1354L45.8334 12.5V37.5C45.8338 38.5512 45.4367 39.5637 44.722 40.3345C44.0072 41.1053 43.0275 41.5774 41.9793 41.6563L41.6668 41.6667H8.33342C7.28221 41.667 6.26973 41.27 5.49894 40.5552C4.72815 39.8405 4.256 38.8607 4.17716 37.8125L4.16675 37.5V12.5C4.16675 12.25 4.18758 12.007 4.22925 11.7708L4.30841 11.4208ZM41.6668 8.33334C41.9195 8.33334 42.1661 8.35487 42.4063 8.39793L42.7605 8.47918L25.0105 26.2292L7.25425 8.47501C7.4848 8.41251 7.72092 8.37084 7.96258 8.35001L8.33342 8.33334H41.6668Z" fill="#F28F02" />
            </g>
            <defs>
                <clipPath id="clip0_147_48">
                    <rect width="50" height="50" fill="none" />
                </clipPath>
            </defs>
        </svg>
    );
}