export const LogoBackdrop = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="190" height="147" viewBox="0 0 190 147" fill="none">
            <ellipse cx="95" cy="73.5" rx="95" ry="73.5" fill="url(#paint0_linear_96_393)" />
            <defs>
                <linearGradient id="paint0_linear_96_393" x1="0" y1="73.5" x2="190" y2="73.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFBD92" />
                    <stop offset="1" stop-color="#FF948D" />
                </linearGradient>
            </defs>
        </svg>
    );
}