export const KeyFeature1 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79" fill="none">
            <g clip-path="url(#clip0_22_53)">
                <path d="M23.6313 19.6915L0 28.6984L14.4559 49.8754V75.1335L39.566 68.53L44.6078 38.0299L39.566 0L23.6313 19.6915Z" fill="#FFF161" />
                <path d="M55.369 19.6915L39.5664 0V68.53L64.5444 75.1335V49.8754L79.0004 28.6984L55.369 19.6915Z" fill="#FADF00" />
                <path d="M6.97168 61.9056C6.97168 71.3317 14.6404 79.0004 24.0667 79.0004L26.6819 62.0157L24.0667 44.8105C14.6404 44.8105 6.97168 52.4793 6.97168 61.9056Z" fill="#AAE392" />
                <path d="M24.0664 44.8105V79.0004C33.4926 79.0004 41.1613 71.3317 41.1613 61.9056C41.1613 52.4793 33.4926 44.8105 24.0664 44.8105Z" fill="#71C6A1" />
                <path d="M22.1781 61.9519L19.3932 59.167L16.1201 62.4401L22.1781 68.4982L24.0666 66.6098L24.9945 62.3901L24.0666 60.0636L22.1781 61.9519Z" fill="#EDF6FF" />
                <path d="M29.5756 54.5547L24.0664 60.064V66.6102L32.8487 57.8278L29.5756 54.5547Z" fill="#D7E9FE" />
            </g>
            <defs>
                <clipPath id="clip0_22_53">
                    <rect width="79" height="79" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}