.user {
  background: #FFF;

  /* Drop Shadow/400 */
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-100) var(--sds-color-black-200), var(--sds-size-depth-0) var(--sds-size-depth-100) var(--sds-size-depth-100) var(--sds-size-depth-negative-100) var(--sds-color-black-100);

  padding: 54px 135px;

  display: flex;
  align-items: center;
}

.user .avatar {
  width: 228px;
  height: 292px;
  border-radius: 0px 143px 0px 112px;
  margin-right: 226px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.user .details {
  display: flex;
  flex-direction: column;
}

.user h1 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user .details .location {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  /* align-items: center; */
  gap: 6px;

  margin-top: 30px;
}

.user .details .location span {
  margin-top: 6px;
}

.user .details .reviews-count {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 30px;
}

.contributions {
  background: linear-gradient(88deg, #F9EDE5 -124.49%, #F5F5F5 227.99%);

  padding-top: 104px;
  padding-bottom: 280.76px;
}

.contributions h2 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 60px;
  padding-left: 111px;
}

.contributions .review-rows {
  display: flex;
  flex-direction: column;
  gap: 211.76px;
}

.review-rows .empty-list {
  color: #000;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  text-align: center;
}