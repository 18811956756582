export const PinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
            <path d="M35.1276 3.20235C37.4403 1.65818 38.5985 0.886099 39.8557 1.0136C41.1166 1.13756 42.0976 2.1186 44.0633 4.08422L53.562 13.583C55.5276 15.5486 56.5087 16.5296 56.6326 17.7905C56.7566 19.0513 55.988 20.2059 54.4474 22.5186L48.6037 31.2771C46.6168 34.2628 45.6216 35.7538 44.8282 37.3476C44.2398 38.528 43.7344 39.7481 43.316 40.999C42.7493 42.6884 42.3951 44.4486 41.6939 47.9655L41.021 51.3478L41.0139 51.369C40.8975 51.9428 40.6408 52.4787 40.2665 52.9289C39.8923 53.3792 39.4123 53.7296 38.8695 53.9489C38.3267 54.1682 37.738 54.2495 37.1561 54.1856C36.5742 54.1217 36.0172 53.9145 35.5349 53.5826L35.5172 53.5684L35.4216 53.5046C23.1728 45.0164 12.555 34.389 4.07783 22.1326L4.06366 22.1113C3.73175 21.6291 3.52457 21.0721 3.46064 20.4901C3.3967 19.9082 3.47803 19.3195 3.69732 18.7767C3.91662 18.2339 4.26706 17.7539 4.71728 17.3797C5.16749 17.0055 5.70345 16.7487 6.27721 16.6323L6.29846 16.6288L9.68075 15.9523C13.1976 15.2476 14.9578 14.8969 16.6472 14.3303C17.8969 13.9116 19.1158 13.4063 20.2951 12.818C21.8889 12.0246 23.3835 11.0294 26.3691 9.03902L35.1276 3.20235Z" fill="url(#paint0_linear_109_46)" stroke="url(#paint1_linear_109_46)" stroke-width="2" />
            <path d="M1 56.6465L16.9375 40.709L1 56.6465Z" fill="url(#paint2_linear_109_46)" />
            <path d="M1 56.6465L16.9375 40.709" stroke="url(#paint3_linear_109_46)" stroke-width="2" stroke-linecap="round" />
            <defs>
                <linearGradient id="paint0_linear_109_46" x1="30.0425" y1="1" x2="30.0425" y2="54.2068" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFBC94" stop-opacity="0.29" />
                    <stop offset="1" stop-color="#F9EDE5" />
                </linearGradient>
                <linearGradient id="paint1_linear_109_46" x1="30.0425" y1="1" x2="30.0425" y2="54.2068" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF8A8C" />
                    <stop offset="1" stop-color="#FFBC94" stop-opacity="0.29" />
                </linearGradient>
                <linearGradient id="paint2_linear_109_46" x1="8.96875" y1="40.709" x2="8.96875" y2="56.6465" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFBC94" stop-opacity="0.29" />
                    <stop offset="1" stop-color="#F9EDE5" />
                </linearGradient>
                <linearGradient id="paint3_linear_109_46" x1="8.96875" y1="40.709" x2="8.96875" y2="56.6465" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF8A8C" />
                    <stop offset="1" stop-color="#FFBC94" stop-opacity="0.29" />
                </linearGradient>
            </defs>
        </svg>
    );
}